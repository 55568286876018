import React from 'react';
import Slider from 'react-slick';
import './Dermatology.css';


// Flattened product ranges (using example provided)
const productRanges = [
    { src: 'assets/images/003.jpg'},
    { src: 'assets/images/004.jpg'},
    { src: 'assets/images/005.jpg'},
    { src: 'assets/images/006.jpg'},
    { src: 'assets/images/007.jpg'},
    { src: 'assets/images/008.jpg'},
    { src: 'assets/images/009.jpg'}
];

// Custom Arrow Components
const NextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
        <img src='assets/images/next.png' alt='next-icon' />
    </div>
);

const PrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
        <img src='assets/images/prev.png' alt='prev-icon' />
    </div>
);

const Dermatology = () => {
    const settings = {
        infinite: true, // Loop the carousel
        speed: 500, // Transition speed
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Time between slides in milliseconds
        nextArrow: <NextArrow />, // Custom next arrow
        prevArrow: <PrevArrow />, // Custom previous arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section id='Dermatology-section'>
            <div className="main-container">
                <div className="carousel-container">
                    <h1 className="text-d">Dermatology & Cosmetics</h1>
                    <Slider {...settings}>
                        {productRanges.map((item, index) => (
                            <div key={index} className="carousel-card-d">
                                <img src={item.src} alt={item.name} />
                                
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default Dermatology;
