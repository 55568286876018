import React from 'react';
import Slider from 'react-slick';
import './PackagingMaetrials.css'; // Your custom styles for carousel

const carouselImages = [
    { src: 'assets/images/1.png', name: 'PLAIN CORRUGATED BOXES' },
    { src: 'assets/images/2.png', name: 'PRINTED CORRUGATED BOXES' },
    { src: 'assets/images/3.png', name: 'CORRUGATED BOXES WITH INNER FITMENTS' },
    { src: 'assets/images/4.png', name: 'AUTO PARTITION BOXES' },
    { src: 'assets/images/5.png', name: 'MAILER BOXES' },
    { src: 'assets/images/6.png', name: 'TELESCOPIC BOXES' },
    { src: 'assets/images/7.png', name: 'TRAY BOXES' },
    { src: 'assets/images/8.png', name: 'CUSTOMISED DIE PUNCH BOXES' },
    { src: 'assets/images/9.png', name: 'PALLETISED BOX' },
    { src: 'assets/images/10.png', name: 'PRINTED INNER CARTONS' },
    { src: 'assets/images/11.png', name: 'CUSTOMISED DIE PUNCH CARTONS' },
    { src: 'assets/images/12.png', name: 'CARD BOARD SHEETS' },
    { src: 'assets/images/13.png', name: 'CORRUGATED ROLL' },
    { src: 'assets/images/14.png', name: 'PAPER ANGLE BOARD & EDGE PROTECTOR' },
    { src: 'assets/images/15.png', name: 'PP BOX STRAPPING' },
    { src: 'assets/images/16.png', name: 'STRETCH FILM ROLL' },

];

// Custom Arrow Components
const NextArrow = ({ onClick }) => {
    return (
        <div className="next-arrow " onClick={onClick}>
            <img src='assets/images/next.png' alt='next-icon' />
        </div>
    );
};

const PrevArrow = ({ onClick }) => {
    return (
        <div className="pre-arrow " onClick={onClick}>
            <img src='assets/images/next.png' alt='next-icon' />
        </div>
    );
};

const PackagingMaetrials = () => {
    const settings = {
        infinite: true, // Loop the carousel
        speed: 500, // Transition speed
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Time between slides in milliseconds
        nextArrow: <NextArrow />, // Custom next arrow
        prevArrow: <PrevArrow />, // Custom previous arrow
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const firstHalfImages = carouselImages.slice(0, 8);
    const secondHalfImages = carouselImages.slice(8, 16);
    return (
        <section id='PackagingMaetrials-section'>
            <div className="main-container">
                <div className="carousel-container">
                    <h1 className="text-p">Packaging Materials</h1>
                    <Slider {...settings}>
                        {firstHalfImages.map((item, index) => (
                            <div key={index} className="carousel-card">
                                <img src={item.src} alt={item.name} />
                                <p className="image-name">{item.name}</p>
                            </div>
                        ))}
                    </Slider>
                    <Slider {...settings}>
                        {secondHalfImages.map((item, index) => (
                            <div key={index} className="carousel-card">
                                <img src={item.src} alt={item.name} />
                                <p className="image-name">{item.name}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
};

export default PackagingMaetrials;
