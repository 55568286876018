import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';
import '../components/Footer.css';
import { Tooltip } from 'react-tooltip';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_zbt7nle', 'template_orvt1x7', e.target, 'h0M7zX5r0_xQ-laXb')
            .then((result) => {
                setStatus('Message sent successfully!');
                setFormData({ name: '', email: '', message: '' }); // Reset form
            }, (error) => {
                setStatus('Failed to send message.');
            });
    };

    return (
        <section id="Contact-section" className='contact-section'>
            <div className="contact-page">
                <div className="container">
                    <h1 className='text-c'>Contact Us</h1>
                    <div className="contact-content">
                        <div className="contact-form">
                            <form id="subscribe" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder="Your Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        name="email"
                                        type="email"
                                        id="email"
                                        placeholder="Your Email Address"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        id="message"
                                        placeholder="Your Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        id="form-submit"
                                        className="main-dark-button"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                            {status && <p>{status}</p>}
                        </div>
                        <div className="contact-info">
                            <div className="info-group">
                                <div className="info-group">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d236351.3555290213!2d73.02201968834352!3d22.240460855841956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc3b036ba94c3%3A0x37fbe7db5b116f43!2sMARUTI%20PACKAGING!5e0!3m2!1sen!2sin!4v1724851991892!5m2!1sen!2sin"
                                        style={{ width: "100%", height: "350px", border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        title="Google Maps Location"
                                    ></iframe>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="contact-info1">
                            <div className="info-group">
                                <ul className="contact-row">
                                    <li className="contact-item">
                                        Office & Plant Address:                                        
                                        <a
                                            href="https://maps.app.goo.gl/tnn3b1gEkiuCBVg79"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Plot No. 116, Por Industrial Park, Por,
                                             Vadodara-391243, Gujarat, India.
                                        </a>
                                    </li>
                                    <li className="contact-item">
                                        Phone:                                    
                                        <a href="tel:+919727818790">+91 9727818790</a>
                                    </li>
                                    <li className="contact-item">
                                        Email:                                    
                                        <a href="mailto:sales.marutipackaging@gmail.com">sales.marutipackaging@gmail.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <div className="under-footer">
                                <p>
                                    Copyright © 2024 Maruti Packaging All Rights Reserved.
                                    <br />
                                    Design By:{" "}
                                    <span
                                        data-tooltip-id="contactInfo"
                                        style={{ cursor: "pointer", textDecoration: "none", color: "white" }}
                                    >
                                        Utsav Patel
                                    </span>
                                    <Tooltip id="contactInfo" place="top" effect="solid">
                                        Email: utsavpatel9719@gmail.com <br />
                                        Contact: +91 9712248838
                                    </Tooltip>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </section>
    );
}

export default Contact;
