import React from 'react';
import './Home.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../components/Header';
import Aboutus from '../pages/Aboutus';
import Contact from '../pages/Contact';
import PackagingMaetrials from '../pages/PackagingMaetrials';
import Dermatology from '../pages/Dermatology';

function Home() {
    return (
        <>
            <Header />
            <Aboutus />
            <PackagingMaetrials />
            <Dermatology />
            <Contact />
        </>
    );
}

export default Home;
