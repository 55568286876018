import React, { useEffect, useState } from 'react';
import "./Header.css";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = (8 * window.innerHeight) / 100; // Offset 8vh
      const sectionTop = section.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth',
      });
    }
    setIsMenuOpen(false); // Close mobile menu after link click
  };

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={isScrolled ? 'transparent' : ''}>
      <div className='Header-container'>
        <div className='right-section'>
          <img src="assets/images/logo1.png" alt="Logo" />
          <h2 className='text-h'>Maruti Packaging</h2>
        </div>
        <div className='left-section'>
          <button className='button' onClick={() => scrollToSection('Aboutus-section')}>About Us</button>

          <div className="button products-dropdown">
            Products
            <ul className="dropdown-menu">
              <li>
                <button className='button-t' onClick={() => scrollToSection('PackagingMaetrials-section')}>
                  Packaging Materials
                </button>
              </li>
              <li>
                <button className='button-t' onClick={() => scrollToSection('Dermatology-section')}>
                  Dermatology & Cosmetics
                </button>
              </li>
            </ul>
          </div>

          <button className='button' onClick={() => scrollToSection('Contact-section')}>Contact Us</button>

          <div className="button products-dropdown">
            E-CATALOG
            <ul className="dropdown-menu">
              <li>
                <a href="/assets/PACKAGING MATERIALS.pdf" download="PACKAGING MATERIALS.pdf">
                  <button className='button-t'>Packaging Materials</button>
                </a>
              </li>
              <li>
                <a href="/assets/DERMATOLOGY & COSMETICS.pdf" download="DERMATOLOGY & COSMETICS.pdf">
                  <button className='button-t'>Dermatology & Cosmetics</button>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className='hamburger' onClick={toggleMenu}>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className='mobile-menu'>
            <span className="styled-button" onClick={() => scrollToSection('Aboutus-section')}>About Us</span>
            <span className="styled-button" onClick={() => scrollToSection('PackagingMaetrials-section')}>Packaging Maetrials</span>
            <span className="styled-button" onClick={() => scrollToSection('Dermatology-section')}>Dermatology & Cosmetics</span>
            <span className="styled-button" onClick={() => scrollToSection('Contact-section')}>Contact Us</span>
            <span className="styled-button">
              <a className='txta' href="/assets/PACKAGING MATERIALS.pdf" download="PACKAGING MATERIALS.pdf">Packaging Maetrials brochure</a>
            </span>
            <span className="styled-button">
              <a className='txta' href="/assets/DERMATOLOGY & COSMETICS.pdf" download="DERMATOLOGY & COSMETICS.pdf"> Dermatology & Cosmetics brochure</a>
            </span>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
