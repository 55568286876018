import React, { useState, useEffect } from 'react';
import './Aboutus.css';

function Aboutus() {
  const images = [
    '/assets/images/ABOUT US 001.jpg',
    '/assets/images/ABOUT US 002.jpeg',
    '/assets/images/ABOUT US 003.jpg',
    '/assets/images/ABOUT US 004.jpeg',
    '/assets/images/ABOUT US 005.jpg',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000); // Changed to 5 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    <section id="Aboutus-section" className="aboutus-section">
      <div className="container">
        <div className="content-row">
          <div className="left-content">
            <h2 className="text-a">ABOUT US</h2>
            <p>MARUTI PACKAGING has been a trusted name in the packaging and cosmetics industry since 2019 located at Vadodara, Gujarat, India.</p>
            <p>We are a merchant cum manufacturer and supplier of high-quality and eco-friendly packaging cartons & boxes and dermatology & cosmetics products.</p>
            <p>Our vast experience helps us to understand the exact needs of clients and provide them with attractive and innovative packaging at competitive rates with high quality and durability. We are well-equipped with a modern technology manufacturing facility and various testing equipment to achieve the desired quality standards. We provide one-stop solutions for packaging specializing in custom design, manufacturing and distribution for various industries. Our commitment to quality, timely delivery and customer satisfaction has earned us a reputation as a leading packaging manufacturer. We can convert 10 tons of paper into boxes per day.</p>
            <p>We supply an extensive range of high-quality products like skin care, hair care, personal care and bath & body products. To benefit the medical, cosmetic and retail industries worldwide, we provide private label formulation, branding and packaging services specializing in face care, body care and hair care with quality-controlled manufacturing and delivery. We supply safe, reliable and responsible products. Our offered range is processed with high-quality raw materials and advanced technology as per international standards. These products are acclaimed for their superior quality, accurate compositions and high effectiveness.</p>
            <p>Our vision is to be the best merchant, manufacturer and supplier who can add value to your brand through innovation and quality.</p>
          </div>
          <div className="right-content">
            <img className="img" src={images[currentImageIndex]} alt={`About us ${currentImageIndex + 1}`} />
            <div className="info-content">
              <div className="section">
                <h2 className="text-w">Why Us?</h2>
                <ul>
                  <li>ISO 9001:2015 certified</li>
                  <li>Innovative designs</li>
                  <li>Customized solutions</li>
                  <li>Competitive prices with high quality</li>
                </ul>
              </div>
              <div className="section">
                <h2 className="text-o">OUR STRENGTHS</h2>
                <ul>
                  <li>Latest technology plant and machinery</li>
                  <li>Experienced team</li>
                  <li>Testing Facility</li>
                  <li>Time Bound Delivery</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;